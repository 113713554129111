import React                from "react";
import Styled               from "styled-components";
import Analytics            from "Utils/Analytics";
import Store                from "Utils/Store";

// Components
import Slider               from "Components/Utils/Slider";



// Styles
const Container = Styled.section`
    margin-bottom: 32px;
`;



/**
 * The Banners
 * @returns {React.ReactElement}
 */
function Banners() {
    const { banners } = Store.useState();

    const showPage = Store.useShowPage();
    const getSpace = Store.useGetSpace();


    // Handles the Click
    const handleClick = (elem) => {
        if (elem.url) {
            window.open(elem.url, "_blank");
            Analytics.gotoBannerUrl(elem.url);
        } else {
            const space = getSpace(elem.spaceID);
            showPage(space);
            Analytics.gotoSpaceFromBanner(elem.id, space.name);
        }
    };


    // Do the Render
    if (!banners.length) {
        return <React.Fragment />;
    }
    return <Container>
        <Slider
            data={banners}
            onClick={handleClick}
            withArrows
            withDots
            alwaysDots
            autoSlide
        />
    </Container>;
}

export default Banners;

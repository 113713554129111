import React                from "react";
import PropTypes            from "prop-types";

// Components
import Backdrop             from "./Backdrop";
import Button               from "./Button";

// Styled
import Styled, {
    keyframes, css,
} from "styled-components";



// Animations
const open = keyframes`
    from { opacity: 0; transform: scale(0.8); }
    to   { opacity: 1; transform: scale(1);   }
`;
const close = keyframes`
    from { opacity: 1; transform: scale(1);   }
    to   { opacity: 0; transform: scale(0.8); }
`;

// Styles
const Container = Styled.div.attrs(({ isClosing }) => ({ isClosing }))`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    background-color: white;
    border-radius: var(--border-radius-big);
    max-width: calc(100% - 2 * var(--main-padding));
    animation: ${(props) => props.isClosing ? css`${close}` : css`${open}`} 0.3s ease-out;
    overflow: auto;
`;

const Close = Styled(Button)`
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 20px;
    cursor: pointer;
`;

const Title = Styled.h3`
    text-align: center;
    margin: 0;
    color: #24374e;
    font-size: 18px;
    font-weight: 600;

    :empty {
        display: none;
    }
`;



/**
 * The Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Dialog(props) {
    const { className, title, onClose, children } = props;

    // The Current State
    const [ isClosing, setClosing ] = React.useState(false);


    // Handles the Click
    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    // Handles the Dialog Close
    const handleClose = () => {
        if (isClosing) {
            return;
        }
        setClosing(true);
        window.setTimeout(() => {
            setClosing(false);
            onClose();
        }, 300);
    };


    // Do the Render
    return <Backdrop isClosing={isClosing} onClick={handleClose}>
        <Container
            className={className}
            isClosing={isClosing}
            onClick={handleClick}
        >
            <Close icon="close" onClick={handleClose} />
            <Title>{title}</Title>
            {children}
        </Container>
    </Backdrop>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Dialog.propTypes = {
    className : PropTypes.string,
    title     : PropTypes.string,
    onClose   : PropTypes.func,
    children  : PropTypes.any,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
Dialog.defaultProps = {
    className : "",
    title     : "",
};

export default Dialog;

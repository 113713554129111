import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";



// Styles
const Container = Styled.section`
    --product-width: calc((100% - var(--product-gap) * (var(--product-columns) - 1)) / var(--product-columns));

    @media (max-width: 600px) {
        --product-columns: 2;
    }

    display: flex;
    flex-wrap: wrap;
    gap: var(--product-gap);
    padding: 4px;
`;



/**
 * The Grid
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Grid(props) {
    const { children } = props;

    // Do the Render
    return <Container>
        {children}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Grid.propTypes = {
    children : PropTypes.any,
};

export default Grid;

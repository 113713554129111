import React                from "react";
import PropTypes            from "prop-types";

// Components
import Icon                 from "./Icon";

// Styled
import Styled, {
    keyframes,
} from "styled-components";



// Animations
const animation = keyframes`
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(0.8, 0.8);
    }
`;

// Styles
const Container = Styled.button.attrs(({ isPrimary, isSecondary, forText, isDisabled }) => ({ isPrimary, isSecondary, forText, isDisabled }))`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    margin: 0;
    border: none;
    border-radius: var(--border-radius-small);
    font-size: 16px;
    flex-shrink: 0;
    cursor: ${(props) => props.isDisabled ? "not-allowed" : "pointer"};

    &:focus {
        animation: ${animation} 0.5s ease-in-out backwards;
    }
    &:active {
        animation: none;
    }

    ${(props) => props.isPrimary ? `
        background-color: var(--store-button-bg);
        color: var(--store-button-text);
    ` : (props.isSecondary ? `
        background-color: var(--store-buy-bg);
        color: var(--store-buy-text);
    ` : `
        background-color: #f1f3f8;
        color: rgb(34, 34, 34);
    `)}

    ${(props) => props.forText && `
        width: auto;
        min-width: 32px;
    `}
`;



/**
 * The Button
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Button(props) {
    const {
        isHidden, className, icon, message, onClick, onContextMenu,
        isPrimary, isSecondary, isDisabled,
    } = props;


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <Container
        className={`btn ${className}`}
        isPrimary={isPrimary}
        isSecondary={isSecondary}
        forText={!!message}
        isDisabled={isDisabled}
        onClick={!isDisabled ? onClick : null}
        onContextMenu={!isDisabled ? onContextMenu : null}
    >
        {!!icon && <Icon icon={icon} />}
        {message}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Button.propTypes = {
    isHidden      : PropTypes.bool,
    className     : PropTypes.string,
    icon          : PropTypes.string,
    message       : PropTypes.string,
    onClick       : PropTypes.func,
    onContextMenu : PropTypes.func,
    isPrimary     : PropTypes.bool,
    isSecondary   : PropTypes.bool,
    isDisabled    : PropTypes.bool,
    children      : PropTypes.any,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
Button.defaultProps = {
    isHidden  : false,
    className : "",
};

export default Button;

import React                from "react";
import Styled               from "styled-components";
import Analytics            from "Utils/Analytics";
import Store                from "Utils/Store";



// Styles
const Container = Styled.section.attrs(({ columns }) => ({ columns }))`
    --spaces-size: 100px;

    max-width: ${(props) => `calc((var(--spaces-size) + 16px) * ${props.columns} - 16px)`};
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
    grid-auto-rows: var(--spaces-size);
    gap: 16px;
    margin: 0 auto;
    padding: 4px;
    overflow-x: auto;
`;

const Content = Styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: var(--spaces-size);
    min-width: var(--spaces-size);
    gap: 8px;
    padding: 4px 8px 4px 8px;
    border-radius: var(--border-radius);
    background-color: white;
    box-shadow: var(--box-shadow);
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
`;

const Image = Styled.img`
    width: 48px;
    height: 48px;
    object-fit: cover;
`;



/**
 * The Spaces
 * @returns {React.ReactElement}
 */
function Spaces() {
    const { spaces } = Store.useState();

    const showPage = Store.useShowPage();


    // The Current State
    const [ width, setWidth ] = React.useState(window.innerWidth);


    // Handle the Resize
    React.useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);


    // Handles the Click
    const handleClick = (space) => {
        Analytics.gotoSpaceFromSpaces(space);
        showPage(space);
    };


    // Variables
    const columns = React.useMemo(() => {
        const maxColumns   = Math.ceil(spaces.length / 2);
        const innerWidth   = Math.min(width, 800) - 48;
        const totalColumns = Math.floor(innerWidth / 116);
        const columns      = Math.max(totalColumns, maxColumns);

        if (columns > columns / 2 && spaces.length - columns <= 2) {
            return spaces.length;
        }
        return columns;
    }, [ spaces.length, width ]);


    // Do the Render
    if (!spaces.length) {
        return <React.Fragment />;
    }
    return <Container columns={columns}>
        {spaces.map((elem) => <Content
            key={elem.id}
            onClick={() => handleClick(elem)}
        >
            <Image
                src={elem.imageUrl}
                alt={elem.name}
            />
            {elem.name}
        </Content>)}
    </Container>;
}

export default Spaces;
